export const STORAGE_PREFIX = `SAFSIMS`;
export const ACCESS_TOKEN = `${STORAGE_PREFIX}_ACCESS_TOKEN`;
export const REFRESH_TOKEN = `${STORAGE_PREFIX}_REFRESH_TOKEN`;
export const USER = `${STORAGE_PREFIX}_USER`;

export const TOP_RIGHT = 'top-right';
export const SUCCESS = 'success';
export const ERROR = 'error';

const url = window.location.href;
const id = url.replace(window.location.protocol + '//', '').split('.')[0];

const school_id =
  id.substring(0, 10) === 'localhost:'
    ? id.substring(0, 14) === 'localhost:5000'
      ? 'safsims'
      : 'ICS'
    : id;

export const SCHOOL_ID = school_id;
export const EDITOR_APIKEY = `nwtx8785vktbbbqadu4o8ddza1s2abp1hqat5niqpjyvr1fk`;
