/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationLogStatusStatistics } from '../models/NotificationLogStatusStatistics';
import type { PaginatedResponseDeliveryReportResponse } from '../models/PaginatedResponseDeliveryReportResponse';
import type { PaginatedResponseMessageSendFrequency } from '../models/PaginatedResponseMessageSendFrequency';
import type { PaginatedResponseNotificationLogResponse } from '../models/PaginatedResponseNotificationLogResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationLogControllerService {
  /**
   * @returns PaginatedResponseNotificationLogResponse OK
   * @throws ApiError
   */
  public static allLog({
    pageNumber,
    pageSize = 15,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseNotificationLogResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/logs',
      query: {
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }

  /**
   * @returns PaginatedResponseDeliveryReportResponse OK
   * @throws ApiError
   */
  public static messageRecipients({
    id,
    pageNumber,
    pageSize = 50,
  }: {
    id: string;
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseDeliveryReportResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/logs/{id}/delivery-reports',
      path: {
        id: id,
      },
      query: {
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }

  /**
   * @returns NotificationLogStatusStatistics OK
   * @throws ApiError
   */
  public static getStatusStatistics({
    startDate,
    endDate,
    status,
  }: {
    startDate: string;
    endDate: string;
    status?: 'SENT' | 'FAILED' | 'PENDING' | 'DELIVERED' | 'READ';
  }): CancelablePromise<NotificationLogStatusStatistics> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/logs/status-statistics',
      query: {
        'start-date': startDate,
        'end-date': endDate,
        status: status,
      },
    });
  }

  /**
   * @returns PaginatedResponseMessageSendFrequency OK
   * @throws ApiError
   */
  public static logFrequency({
    pageNumber,
    pageSize = 15,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseMessageSendFrequency> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/logs/frequencies',
      query: {
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }
}
