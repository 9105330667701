import { useState, useCallback } from 'react';
import { Box, Button, Input, Notify } from '@flexisaf/flexibull2';
import localforage from 'localforage';
import { apiWrapper } from 'utils/http-client';
import { handleError } from 'utils/utils';
import { ACCESS_TOKEN, REFRESH_TOKEN, SCHOOL_ID } from 'utils/constants';
import { AuthControllerService, LoginRequest } from 'generated';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, logOut } from '../../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, token } = useSelector((state: any) => state.auth);

  const createLogin = useCallback(
    async (payload: LoginRequest) => {
      setLoading(true);
      try {
        const response = await apiWrapper(() =>
          AuthControllerService.login({
            requestBody: payload,
          }),
        );

        dispatch(setCredentials(response));

        // const access_token = response?.ums_login_response?.access_token;
        // const refresh_token = response?.ums_login_response?.refresh_token;

        // await localforage.setItem(ACCESS_TOKEN, access_token);
        // await localforage.setItem(REFRESH_TOKEN, refresh_token);

        setLoading(false);
        Notify(`Successfully logged in.`, {
          status: 'success',
          position: 'top-right',
        });

        return { success: true };
      } catch (error) {
        setLoading(false);
        handleError(error);
        return { success: false, error };
      }
    },
    [dispatch],
  );

  const logoutUser = () => {
    dispatch(logOut());
  };

  return {
    loadingLogin: loading,
    createLogin,
    user,
    token,
    logoutUser,
  };
};
