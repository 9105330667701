/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRoleRequest } from '../models/CreateRoleRequest';
import type { PrivilegeGroupResponse } from '../models/PrivilegeGroupResponse';
import type { UmsPaginatedResponseUmsRoleResponse } from '../models/UmsPaginatedResponseUmsRoleResponse';
import type { UmsRoleResponse } from '../models/UmsRoleResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleControllerService {
  /**
   * @returns UmsPaginatedResponseUmsRoleResponse OK
   * @throws ApiError
   */
  public static getRoles({
    offset,
    limit,
    search,
  }: {
    offset: number;
    limit: number;
    search?: string;
  }): CancelablePromise<UmsPaginatedResponseUmsRoleResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/roles',
      query: {
        search: search,
        offset: offset,
        limit: limit,
      },
    });
  }

  /**
   * @returns UmsRoleResponse OK
   * @throws ApiError
   */
  public static createRole({
    requestBody,
  }: {
    requestBody: CreateRoleRequest;
  }): CancelablePromise<UmsRoleResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/roles',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UmsRoleResponse OK
   * @throws ApiError
   */
  public static deleteRole({ roleId }: { roleId: string }): CancelablePromise<UmsRoleResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/roles/{roleId}',
      path: {
        roleId: roleId,
      },
    });
  }

  /**
   * @returns UmsRoleResponse OK
   * @throws ApiError
   */
  public static updateRole({
    roleId,
    requestBody,
  }: {
    roleId: string;
    requestBody: CreateRoleRequest;
  }): CancelablePromise<UmsRoleResponse> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/roles/{roleId}',
      path: {
        roleId: roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PrivilegeGroupResponse OK
   * @throws ApiError
   */
  public static getPrivileges(): CancelablePromise<Array<PrivilegeGroupResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/roles/privileges',
    });
  }
}
