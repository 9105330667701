/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedResponseSchoolKycAcquisitionTypeInformation } from '../models/PaginatedResponseSchoolKycAcquisitionTypeInformation';
import type { UpdateKycAndAcquisitionTypeRequest } from '../models/UpdateKycAndAcquisitionTypeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KycConfigControllerService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateKyc({
    requestBody,
  }: {
    requestBody: UpdateKycAndAcquisitionTypeRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/kyc-config',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PaginatedResponseSchoolKycAcquisitionTypeInformation OK
   * @throws ApiError
   */
  public static getAllSchools({
    search,
    countryCode,
    kycCompleted,
    acquisitionType,
    pageNumber,
    pageSize = 10,
  }: {
    search?: string;
    countryCode?: string;
    kycCompleted?: boolean;
    acquisitionType?: 'ACADA_PLUS' | 'PLG' | 'UBEC' | 'MADRASA';
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseSchoolKycAcquisitionTypeInformation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/kyc-config/schools',
      query: {
        search: search,
        country_code: countryCode,
        kyc_completed: kycCompleted,
        acquisition_type: acquisitionType,
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }
}
