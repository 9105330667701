export const SideBarMainMenu = (userPermissions) => {
  return [
    {
      name: 'notification',
      icon: 'saf-notification-status',
      label: 'Notifications',
      navlink: '/notification-configuration',
      privilege: userPermissions.VIEW_NOTIFICATIONS || userPermissions.UPDATE_NOTIFICATIONS,
    },
    {
      name: 'user-management',
      icon: 'saf-people',
      label: 'User Management',
      navlink: '/user-management',
      privilege: userPermissions.VIEW_USERS || userPermissions.UPDATE_USERS,
    },
    {
      name: 'payment-settings',
      icon: 'saf-receipt-item',
      label: 'Fees Configuration',
      navlink: '/payment-settings',
      privilege: userPermissions.VIEW_FEES || userPermissions.UPDATE_FEES,
    },
    {
      name: 'KYC setting',
      icon: 'saf-clipboard-tick',
      label: 'KYC Configuration',
      navlink: '/kyc-configuration',
      privilege: userPermissions.VIEW_KYC || userPermissions.UPDATE_KYC,
    },
    {
      name: 'sms-configuration',
      icon: 'saf-clipboard-tick',
      label: 'SMS Configuration',
      navlink: '/sms-configuration',
      privilege: userPermissions.VIEW_SMS_CONFIG || userPermissions.UPDATE_SMS_CONFIG,
    },
    {
      name: 'subscription-plans',
      icon: 'saf-medal-star',
      label: 'Subscription Plans',
      navlink: '/subscription-management',
      privilege: userPermissions.VIEW_SUBSCRIPTIONS || userPermissions.UPDATE_SUBSCRIPTIONS,
    },
  ].filter(({ privilege }) => privilege === true);
};

export const ProfileMenu = [
  {
    key: 'profile',
    icon: 'saf-frame-1',
    label: 'Profile',
    color: '',
  },
];
