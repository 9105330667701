/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SchoolResponse } from '../models/SchoolResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SchoolsControllerService {
  /**
   * @returns SchoolResponse OK
   * @throws ApiError
   */
  public static allSchools(): CancelablePromise<Array<SchoolResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schools',
    });
  }

  /**
   * @returns SchoolResponse OK
   * @throws ApiError
   */
  public static allUnactivatedSchools(): CancelablePromise<Array<SchoolResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schools/unactivated',
    });
  }

  /**
   * @returns SchoolResponse OK
   * @throws ApiError
   */
  public static allSchoolsByType({
    type,
  }: {
    type: 'ACADA_PLUS' | 'PLG' | 'UBEC' | 'MADRASA';
  }): CancelablePromise<Array<SchoolResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/schools/type/{type}',
      path: {
        type: type,
      },
    });
  }
}
