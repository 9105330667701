import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export enum Permission {
  VIEW_FEES = 'VIEW_FEES',
  UPDATE_FEES = 'UPDATE_FEES',

  VIEW_ROLES = 'VIEW_ROLES',
  UPDATE_ROLES = 'UPDATE_ROLES',

  VIEW_SMS_CONFIG = 'VIEW_SMS_CONFIG',
  UPDATE_SMS_CONFIG = 'UPDATE_SMS_CONFIG',

  VIEW_EMIS = 'VIEW_EMIS',

  VIEW_EMIS_GROUPS = 'VIEW_EMIS_GROUPS',
  UPDATE_EMIS_GROUPS = 'UPDATE_EMIS_GROUPS',

  VIEW_USERS = 'VIEW_USERS',
  UPDATE_USERS = 'UPDATE_USERS',

  VIEW_KYC = 'VIEW_KYC',
  UPDATE_KYC = 'UPDATE_KYC',

  VIEW_SUBSCRIPTIONS = 'VIEW_SUBSCRIPTIONS',
  UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS',

  VIEW_NOTIFICATIONS = 'VIEW_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS',
}

type UserPermissions = Record<Permission, boolean>;

const usePermissions = () => {
  const { privileges, user } = useSelector((state: any) => state.auth);

  const userPermissions: UserPermissions = useMemo(() => {
    const additionalPrivileges = [
      'dashboard:sms:units:r',
      'dashboard:sms:units:u',
      'dashboard:subscription:r',
      'dashboard:subscription:u',
      'dashboard:emis:r',
      'dashboard:emis:group:r',
      'dashboard:emis:group:u',
    ];

    const permissionMap: Record<Permission, string> = {
      [Permission.VIEW_FEES]: 'dashboard:fees:config:r',
      [Permission.UPDATE_FEES]: 'dashboard:fees:config:u',

      [Permission.VIEW_ROLES]: 'dashboard:role:r',
      [Permission.UPDATE_ROLES]: 'dashboard:role:u',

      [Permission.VIEW_SMS_CONFIG]: 'dashboard:sms:units:r',
      [Permission.UPDATE_SMS_CONFIG]: 'dashboard:sms:units:u',

      [Permission.VIEW_EMIS]: 'dashboard:emis:r',

      [Permission.VIEW_EMIS_GROUPS]: 'dashboard:emis:group:r',
      [Permission.UPDATE_EMIS_GROUPS]: 'dashboard:emis:group:u',

      [Permission.VIEW_USERS]: 'dashboard:user:r',
      [Permission.UPDATE_USERS]: 'dashboard:user:u',

      [Permission.VIEW_KYC]: 'dashboard:kyc:r',
      [Permission.UPDATE_KYC]: 'dashboard:kyc:u',

      [Permission.VIEW_SUBSCRIPTIONS]: 'dashboard:subscription:r',
      [Permission.UPDATE_SUBSCRIPTIONS]: 'dashboard:subscription:u',

      [Permission.VIEW_NOTIFICATIONS]: 'dashboard:notification:r',
      [Permission.UPDATE_NOTIFICATIONS]: 'dashboard:notification:u',
    };

    const userPrivileges = user?.roles?.find((role) => role.role.name === 'ONBOARDING_ROLE')
      ? [...privileges, ...additionalPrivileges]
      : privileges;

    return Object.fromEntries(
      Object.entries(permissionMap).map(([key, permissionString]) => [
        key,
        userPrivileges.includes(permissionString),
      ]),
    ) as UserPermissions;
  }, [privileges, user?.roles]);

  return { userPermissions };
};

export default usePermissions;
