/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageDataResponse } from '../models/ImageDataResponse';
import type { NotificationRequest } from '../models/NotificationRequest';
import type { NotificationResponse } from '../models/NotificationResponse';
import type { NotificationUpdateRequest } from '../models/NotificationUpdateRequest';
import type { PaginatedResponseNotificationResponse } from '../models/PaginatedResponseNotificationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationControllerService {
  /**
   * @returns NotificationResponse OK
   * @throws ApiError
   */
  public static updateNotification({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: {
      'notification-update-request': NotificationUpdateRequest;
      'new-attachments'?: Array<Blob>;
      'new-inline-images'?: Array<Blob>;
    };
  }): CancelablePromise<NotificationResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/notifications/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PaginatedResponseNotificationResponse OK
   * @throws ApiError
   */
  public static allUnsentNotifications({
    pageNumber,
    pageSize = 15,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseNotificationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications',
      query: {
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }

  /**
   * @returns NotificationResponse OK
   * @throws ApiError
   */
  public static saveNotification({
    requestBody,
  }: {
    requestBody?: {
      'notification-request': NotificationRequest;
      attachments?: Array<Blob>;
      'inline-images'?: Array<Blob>;
    };
  }): CancelablePromise<NotificationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/notifications',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ImageDataResponse OK
   * @throws ApiError
   */
  public static imageData({
    notificationRequest,
    images,
  }: {
    notificationRequest: NotificationRequest;
    images: Array<Blob>;
  }): CancelablePromise<ImageDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications/image-data',
      query: {
        notificationRequest: notificationRequest,
        images: images,
      },
    });
  }
}
