import { createSlice } from '@reduxjs/toolkit';
import localforage from 'localforage';
import { ACCESS_TOKEN, REFRESH_TOKEN, USER } from 'utils/constants';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

const extractPrivileges = (roles) => {
  return roles?.flatMap((role) => role.role.privileges.map((priv) => priv.name));
};

const initialState = {
  user: null,
  token: null,
  privileges: [],
  currentUserPermissions: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload;
      state.user = user;
      state.token = accessToken;
      state.privileges = extractPrivileges(user.roles);

      localforage.setItem(ACCESS_TOKEN, accessToken);
      localforage.setItem(REFRESH_TOKEN, refreshToken);
      localforage.setItem(USER, user);
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      localforage.removeItem(REFRESH_TOKEN);
      localforage.removeItem(ACCESS_TOKEN);
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setCurrentUserPermissions: (state, action) => {
      state.currentUserPermissions = action.payload;
    },
    initializeAuth: (state, action) => {
      if (action.payload?.accessToken) {
        state.user = action.payload.user;
        state.token = action.payload.accessToken;
        state.privileges = extractPrivileges(action.payload.user.roles);
      }
    },
  },
});

const persistConfig = {
  key: 'auth',
  storage: storageSession,
  whitelist: ['user', 'token', 'privileges', 'currentUserPermissions'], // state fields to persist
};

export const { setCredentials, logOut, initializeAuth, setCurrentUserPermissions } =
  authSlice.actions;
export default persistReducer(persistConfig, authSlice.reducer);
