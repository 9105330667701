/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { UmsPaginatedResponseUserResponse } from '../models/UmsPaginatedResponseUserResponse';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { UserResponse } from '../models/UserResponse';
import type { UserStatistics } from '../models/UserStatistics';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersControllerService {
  /**
   * @returns UmsPaginatedResponseUserResponse OK
   * @throws ApiError
   */
  public static getUsers({
    offset,
    limit,
    search,
    roleId,
    status,
  }: {
    offset: number;
    limit: number;
    search?: string;
    roleId?: string;
    status?: 'ACTIVE' | 'INACTIVE';
  }): CancelablePromise<UmsPaginatedResponseUserResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
      query: {
        search: search,
        roleId: roleId,
        status: status,
        offset: offset,
        limit: limit,
      },
    });
  }

  /**
   * @returns UserResponse OK
   * @throws ApiError
   */
  public static createUser({
    requestBody,
  }: {
    requestBody: CreateUserRequest;
  }): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserResponse OK
   * @throws ApiError
   */
  public static updateUser({
    requestBody,
  }: {
    requestBody: UpdateUserRequest;
  }): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/users',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserResponse OK
   * @throws ApiError
   */
  public static updateUserAccess({
    id,
    blocked,
  }: {
    id: string;
    blocked: boolean;
  }): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/users/access/{id}',
      path: {
        id: id,
      },
      query: {
        blocked: blocked,
      },
    });
  }

  /**
   * @returns UserStatistics OK
   * @throws ApiError
   */
  public static getUserStatistics(): CancelablePromise<UserStatistics> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/statistics',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUser({ id }: { id: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/users/{id}',
      path: {
        id: id,
      },
    });
  }
}
