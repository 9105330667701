/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TemplateResponse } from '../models/TemplateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplatesControllerService {
  /**
   * @returns TemplateResponse OK
   * @throws ApiError
   */
  public static allTemplates(): CancelablePromise<Array<TemplateResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/templates',
    });
  }

  /**
   * @returns TemplateResponse OK
   * @throws ApiError
   */
  public static channelTemplates({
    messageChannel,
  }: {
    messageChannel: string;
  }): CancelablePromise<Array<TemplateResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/templates/{message_channel}',
      path: {
        message_channel: messageChannel,
      },
    });
  }
}
