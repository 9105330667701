/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { UmsLoginResponse } from '../models/UmsLoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthControllerService {
  /**
   * @returns UmsLoginResponse OK
   * @throws ApiError
   */
  public static confirmPasswordResetRequest({
    requestBody,
  }: {
    requestBody: ConfirmPasswordResetRequest;
  }): CancelablePromise<UmsLoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/reset-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UmsLoginResponse OK
   * @throws ApiError
   */
  public static login({
    requestBody,
  }: {
    requestBody: LoginRequest;
  }): CancelablePromise<UmsLoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
