/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedResponseSchoolFeesChargesResponse } from '../models/PaginatedResponseSchoolFeesChargesResponse';
import type { SchoolFeesChargesResponse } from '../models/SchoolFeesChargesResponse';
import type { UpdateFeesConfigChargesRequest } from '../models/UpdateFeesConfigChargesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeesChargesControllerService {
  /**
   * @returns PaginatedResponseSchoolFeesChargesResponse OK
   * @throws ApiError
   */
  public static getFeesCharges({
    paymentMethod,
    search,
    pageNumber,
    pageSize = 10,
  }: {
    paymentMethod: 'PAYSTACK' | 'FLUTTERWAVE' | 'PESAPAL' | 'STRIPE';
    search?: string;
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseSchoolFeesChargesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/fees-charges',
      query: {
        search: search,
        payment_method: paymentMethod,
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }

  /**
   * @returns SchoolFeesChargesResponse OK
   * @throws ApiError
   */
  public static updateFeesCharges({
    requestBody,
  }: {
    requestBody: UpdateFeesConfigChargesRequest;
  }): CancelablePromise<SchoolFeesChargesResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/fees-charges',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
