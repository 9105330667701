/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicSchoolInformationDto } from '../models/BasicSchoolInformationDto';
import type { PaginatedResponseSmsConfigResponse } from '../models/PaginatedResponseSmsConfigResponse';
import type { PaginatedResponseSmsTopupHistoryResponse } from '../models/PaginatedResponseSmsTopupHistoryResponse';
import type { SmsBalanceTopupRequest } from '../models/SmsBalanceTopupRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SmsConfigRestControllerService {
  /**
   * @returns BasicSchoolInformationDto OK
   * @throws ApiError
   */
  public static topupBalance({
    requestBody,
  }: {
    requestBody: SmsBalanceTopupRequest;
  }): CancelablePromise<BasicSchoolInformationDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sms/topup',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns string OK
   * @throws ApiError
   */
  public static getSmsProviders(): CancelablePromise<Array<'TWILIO' | 'VOODOO' | 'MULTITEXTER'>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sms/providers',
    });
  }

  /**
   * @returns PaginatedResponseSmsTopupHistoryResponse OK
   * @throws ApiError
   */
  public static getProviderSmsConfigs({
    schoolId,
    pageSize = 5,
  }: {
    schoolId: string;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseSmsTopupHistoryResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sms/history',
      query: {
        school_id: schoolId,
        page_size: pageSize,
      },
    });
  }

  /**
   * @returns PaginatedResponseSmsConfigResponse OK
   * @throws ApiError
   */
  public static getProviderSmsConfigs1({
    provider,
    search,
    countryCode,
    pageNumber,
    pageSize = 10,
  }: {
    provider: 'TWILIO' | 'VOODOO' | 'MULTITEXTER';
    search?: string;
    countryCode?: string;
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseSmsConfigResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sms/configs',
      query: {
        provider: provider,
        search: search,
        country_code: countryCode,
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }
}
